.register-country-btn {
  width: 350px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 14px 15px 14px;
  margin: 0px 0px 16px 0px;
  background-color: var(--gray-main);
  background-image: url('../../../assets/icons/registration/arrow-down.svg');
  background-repeat: no-repeat;
  background-size: 22px 22px;
  background-position: 95% center;
  border: 1px solid var(--gray-first);
  border-radius: 10px;
  text-align: left;
  transition: border .3s ease-in-out;

  // &:hover {
  //   border: 1px solid var(--green-third);
  //   transition: border .3s ease-in-out;
  // }

  @media (max-width: 767px) {
    width: 300px;
  }
}

.register-country-btn-active {
  width: 350px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 14px 8px 14px;
  margin: 0px 0px 16px 0px;
  background-color: var(--gray-main);
  background-image: url('../../../assets/icons/registration/arrow-down.svg');
  background-repeat: no-repeat;
  background-size: 22px 22px;
  background-position: 95% center;
  border: 1px solid var(--gray-first);
  border: 1px solid var(--gray-first);
  border-radius: 10px;
  text-align: left;
  transition: border .3s ease-in-out;

  // &:hover {
  //   border: 1px solid var(--green-third);
  //   transition: border .3s ease-in-out;
  // }

  @media (max-width: 767px) {
    width: 300px;
  }
}

.register-country-btn-text {
  color: var(--dark-fifth);
}

.register-country-btn-text-active {
  transform: translate(0px, -4px);
  text-align: left;
  color: var(--dark-fifth);
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
}

.valiable-country-modal-container {
  width: 300px;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px auto 0px auto;
  z-index: 1100;
}

.valiable-country-modal-container-image {
  width: 150px;
  height: 150px;
}

.valiable-country-modal-container-text {
  margin: 24px 0px 24px 0px;
  text-align: center;
  font: var(--headline);
}

.valiable-country-modal-container-btn {
  padding: 9px 14px 9px 14px;
  border-radius: 100px;
  background-color: var(--gray-first);
}

.valiable-country-modal-container-btn-text {
  font: var(--button);
  color: var(--green-first);
}

// register modal
.register-country-modal .ant-modal {
  width: 400px !important;
  // transition: transform 1s ease-in-out 1s;


  @media (max-width: 767px) {
    width: 100%;
  }

  .ant-modal-content {
    padding: 16px 16px 16px 16px;
    border-radius: 20px 20px 20px 20px;
    box-shadow: none;
  }

  .ant-modal-close {
    top: 19px;
    width: 34px;
    height: 34px;
    background-color: var(--gray-first);
    border-radius: 50%;

    &:hover {
      color: var(--white);
      background-color: var(--green-second);
      transition: background-color .3s ease-in-out,
                  color .3s ease-in-out;

      .ant-custom-close-icon {
        background-color: var(--white);
        transition: background-color .3s ease-in-out;

        &::before {
          background-color: var(--white);
          transition: background-color .3s ease-in-out;
        }
      }
    }
  }

  .ant-custom-close-icon {
    position: absolute;
    width: 16px;
    height: 1px;
    top: 17px;
    left: 9px;
    transform: rotate(45deg);
    background-color: var(--green-second);
    transition: background-color .3s ease-in-out;
    border-radius: 10px;

    &::before {
      content: '';
      position: absolute;
      width: 16px;
      height: 1px;
      left: 0px;
      top: 0px;
      transform: rotate(90deg);
      border-radius: 10px;
      background-color: var(--green-second);
      transition: background-color .3s ease-in-out;
    }
  }
}

//register select
.register-country-select {
  width: 320px;
  height: 40px;

  @media (max-width: 767px) {
    width: 81vw;

    .ant-select-selector {
      width: 81vw !important;
    }
  }

  .ant-select-selector {
    height: 42px !important;
    padding: 10px 14px !important;
    border: 1px solid var(--green-second) !important;
    border-color: var(--green-second) !important;
    background-color: var(--gray-main) !important;
    border-radius: 10px;
    box-shadow: none !important;

    .ant-select-selection-search {
      inset-inline-start: 14px;
      inset-inline-end: 14px;

      .ant-select-selection-search-input {
        height: 100% !important;
        padding: 0px 0px 0px 24px !important;
        background: no-repeat url('../../../assets/images/registration/search.webp') !important;
        background-size: 20px 20px !important;
        background-position: 0px 10px !important;
      }
    }

    .ant-select-selection-placeholder {
      width: 100%;
      padding: 1px 0px 0px 24px;
      font: var(--input);
      color: var(--dark-fifth);
    }

    .ant-select-selection-item {
      padding-inline-end: 0px;
      padding: 1px 16px 0px 24px;
      overflow-x: hidden;
      font: var(--input);
    }
  }

  .ant-select-clear {
    top: 46%;
    right: 5%;
    // width: 16px;
    // height: 16px;
    border-radius: 50%;
    opacity: 1;
    // background: var(--dark-fifth) !important;
  }

  .anticon-custom-close-circle {
    position: absolute;
    width: 8px;
    height: 1px;
    top: 7px;
    left: 4px;
    transform: rotate(45deg);
    background-color: var(--gray-main);
    transition: background-color .3s ease-in-out;
    border-radius: 10px;

      &::before {
        content: '';
        position: absolute;
        width: 8px;
        height: 1px;
        left: 0px;
        top: 0px;
        transform: rotate(90deg);
        border-radius: 10px;
        background-color: var(--gray-main);
        transition: background-color .3s ease-in-out;
      }
  }

}

.register-country-select-dropdown {
  padding: 0;
  width: 370px;
  border-radius: 0px 0px 20px 20px;
  box-shadow: none;

  @media (max-width: 767px) {
    width: 100vw !important;
    height: calc(var(--vh, 1vh) * 85);
    left: 0 !important;
    border-radius: 0px 0px 0px 0px;
  }

  .rc-virtual-list {
    padding: 0;

    .rc-virtual-list-holder {

      .ant-select-item {
        margin: 0px 12px 0px 8px;
        padding: 12px 12px 12px 12px;
        font-weight: 400px !important;
        background-color: var(--white) !important;
        transition: background-color .3s ease-in-out;

        @media (max-width: 767px) {
          margin: 0px 0px 0px 16px;
          padding: 12px 12px 12px 12px;
        }

        &:hover {
          border-radius: 10px;
          background-color: var(--gray-main) !important;
          transition: background-color .3s ease-in-out;

          .register-country-option-name {
            color: var(--green-first);
            transition: color .3s ease-in-out;
          }
          .register-country-option-name-index {
            color: var(--green-first);
            transition: color .3s ease-in-out;
          }
        }
      }
    }

    .rc-virtual-list-scrollbar {
      display: block !important;
      bottom: 13px !important;
      background-color: var(--gray-first);
    }
    
    .rc-virtual-list-scrollbar-thumb {
      width: 4px !important;
      // height: 35% !important;
      left: 2px !important;
      border-radius: 48px !important;
      background-color: var(--gray-third) !important;
    }
  }
  
  .register-country-option-image {
    width: 34px;
    height: 34px;
    margin: 0px 16px 0px 0px;
  }

  .register-country-option-full-name {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 0px 0px 0px;

    @media (max-width: 767px) {
      padding: 0px 22px 0px 0px;
    }
  }

  .register-country-option-name {
    width: 80%;
    overflow-x: hidden;
    text-overflow: ellipsis;
    font : var(--input);
    color: var(--dark);
    transition: color .3s ease-in-out;
  }

  .register-country-option-name-index {
    color: var(--dark-fifth);
    transition: color .3s ease-in-out;
  }
}
