.email-otp-resend-container {
  margin: 16px 0px 0px 0px;
}

.email-otp-resend-btn {
  font: var(--body-2);
  color: var(--dark);
  cursor: default;
}

.email-otp-resend-btn-active {
  font: var(--body-2);
  color: var(--green-second);
  cursor: pointer;
}

.email-otp-error-container {
  display: flex;
  justify-content: center;
}

.email-otp-error-btn {
  padding: 9px 14px 9px 14px;
  border-radius: 100px;
  background-color: var(--gray-first);
}

.email-otp-error-btn-text {
  font: var(--button);
  color: var(--green-first);
}
