.create-email-googleBtn {
  padding: 14px 73px 14px 107px;
  border-radius: 10px;
  font: var(--button);
  color: var(--green-second);
  background-color: var(--gray-first);
  background-image: url("../../../assets/icons/registration/google.svg");
  background-repeat: no-repeat;
  background-size: 24px 24px;
  background-position: 22% center;
  opacity: 0.5;
  cursor: not-allowed;

  @media (max-width: 767px) {
    padding: 14px 48px 14px 82px;
    background-position: 18% center;
  }
}

.create-email-divider {
  padding: 24px 0;
  position: relative;
  width: 350px;
  display: flex;
  align-items: center;
  justify-content: center;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    bottom: 50%;
    left: 0;
    height: 1px;
    width: 30%;
    background-color: var(--gray-second);
  }

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    bottom: 50%;
    right: 0;
    height: 1px;
    width: 30%;
    background-color: var(--gray-second);
  }

  span {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: var(--dark-fifth);
  }
}

.create-email-container {
  position: relative;

  .create-email-input {
    position: relative;
    width: 350px;
    padding: 0;
    padding-left: 14px;
    padding-right: 14px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: var(--dark);
    border: 1px solid var(--gray-first);
    border-radius: 10px;
    background-color: var(--gray-main);
    box-shadow: none;

    &:hover {
      border-color: var(--gray-first);
    }

    &:focus-visible,
    &:focus-within,
    &:focus {
      border-color: var(--green-second) !important;

      // & + div {
      //   top: 4px;
      //   font-size: 10px;
      //   line-height: 14px;
      // }
    }

    // input {
      padding-top: 21px;
      padding-bottom: 7px;
      line-height: 0;
      background-color: var(--gray-main);
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: var(--dark);

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus {
        -webkit-box-shadow: 0 0 0px 1000px var(--gray-main) inset !important;
      }
    // }

    @media (max-width: 767px) {
      width: 300px;
    }
  }

  .create-email-label {
    position: absolute;
    top: 15px;
    left: 15px;
    display: flex;
    align-items: center;
    width: calc(100% - 30px);
    height: calc(100% - 30px);
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: var(--dark-fifth);
    pointer-events: none;
    transition: all 0.2s ease-in-out;
  }

  .create-email-label-active {
    top: 4px;
    font-size: 10px;
    line-height: 14px;
  }

}

.create-email-block {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.create-email-container-text {
  width: 350px;
  height: 100%;
  display: flex;
  align-items: flex-end;
  margin: 0px 0px 16px 0px;
  text-align: center;
  font: var(--body-4);
}

.create-email-container-text-link {
  text-decoration: none;
  color: var(--blue);
}
