.welcome {
  position: relative;
  padding: 24px 0;
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;

  @media (max-width: 1365px) {
    display: flex;
    align-items: flex-end;
  }

  .background {
    top: 0;
    right: 0;
    position: absolute;
    height: 100%;
    width: 60%;
    background-image: url("../../../assets/images/registration/welcome-bg-4k.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    @media (max-width: 1365px) {
      width: 100%;
      height: 55%;
      background-image: url("../../../assets/images/registration/welcome-bg.jpg");
    }

    @media (max-width: 767px) {}
  }

  .content {
    height: 100%;
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 1365px) {
      width: 100%;
      height: 53%;
      padding-top: 2%;
      background: var(--white);
      border-radius: 20px 20px 0px 0px;
      z-index: 2;
    }

    @media (max-width: 767px) {
      height: 58%;
      padding-top: 4%;
    }

    img {
      width: 92px;
      height: 30px;

      @media (max-width: 1365px) {
        position: absolute;
        top: 8px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
      }
    }

    .middleContent {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      span {
        display: inline-block;
        margin-bottom: 10px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        line-height: 28px;
        color: var(--dark);
        text-align: center;

        @media (max-width: 767px) {
          font-size: 20px;
          line-height: 26px;
          margin-bottom: calc(var(--vh, 1vh) * 0.7);
        }
      }

      h1 {
        margin-bottom: 16px;
        font-family: 'Crimson Text';
        font-style: normal;
        font-weight: 700;
        font-size: 56px;
        line-height: 50px;
        text-transform: uppercase;
        color: #2B5A3C;
        text-align: center;

        @media (max-width: 1365px) {
          margin-bottom: calc(var(--vh, 1vh) * 1);
        }

        @media (max-width: 767px) {
          font-size: 34px;
          line-height: 34px;
        }
      }

      p {
        width: 65%;
        display: inline-block;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        color: var(--dark-fifth);
        text-align: center;

        @media (max-width: 767px) {
          display: none;
        }
      }
    }

    .bottomContent {

      article {
        margin-bottom: 24px;
        padding: 24px;
        background: var(--gray-main);
        border-radius: 24px;

        @media (max-width: 767px) {
          margin-bottom: calc(var(--vh, 1vh) * 2);
          padding: 16px;
        }

        p {
          margin-bottom: 16px;
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 500;
          font-size: 22px;
          line-height: 28px;
          color: var(--dark);

          @media (max-width: 767px) {
            margin-bottom: calc(var(--vh, 1vh) * 1);
            font-size: 16px;
            line-height: 20px;
          }
        }

        ul {

          li {
            padding-left: 18px;
            font-family: "Poppins", sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 20px;
            color: var(--dark);
            background-image: url("../../../assets/icons/registration/check.svg");
            background-repeat: no-repeat;
            background-position: left center;

            @media (max-width: 767px) {
              font-size: 13px;
              line-height: 20px;
            }

            &:not(:last-child) {
              margin-bottom: 8px;
            }
          }
        }
      }
    }
  }
}
