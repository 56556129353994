html,body {
  font-family: "Poppins", sans-serif;
  overflow: hidden;
  caret-color: var(--green-second);
}

button {
  cursor: pointer;
  padding: 0;
  background-color: transparent;
  border: none;
  outline: none;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

h1,h2,h3,h4,h5,h6,p {
  margin: 0;
}

:root {
  --green-first: #2B5A3C;
  --green-second: #426D51;
  --green-third: #5A7F67;
  --dark: #000000;
  --dark-fifth: #808080;
  --gray-main: #FAFAFA;
  --gray-first: #F2F2F2;
  --gray-second: #E5E5E5;
  --gray-third: #D9D9D9;
  --gray-fourth: #CCCCCC;
  --blue: #0370F9;
  --red: #FF0000;
  --white: #FFFFFF;
  --body-2: normal 400 16px/22px 'Poppins';
  --body-3: normal 400 13px/20px 'Poppins';
  --body-4: normal 400 10px/14px 'Poppins';
  --button: normal 500 17px/22px 'Poppins';
  --headline: normal 500 22px/28px 'Poppins';
  --headline-5: normal 500 13px/18px 'Poppins';
  --input: normal 400 16px/22px 'Poppins';
}

