.boxShadowMobile {
    @media (max-width: 767px) {

        &::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 10%;
            left: 0;
            bottom: -10%;
            box-shadow: 0px -42px 54px 29px rgba(150, 150, 150, 0.9);
            z-index: 2;
        }
    }
}

.registerTop {
    h3{
        width: 482px;
    
        @media (max-width: 767px) {
            width: 80%;
        }
    }
}

.registerFormContainer {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 767px) {
        overflow-y: scroll;
    }
}

.registerFormContainer::-webkit-scrollbar {
    display: none;
  }

.registerForm {
    width: 852px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0px;
    gap: 16px;

    @media (max-width: 1365px) {
        width: 500px;
        justify-content: space-between;
    }

    @media (max-width: 767px) {
        width: 357px;
        padding-bottom: 48px;
    }

    label {
        position: relative;
        width: 201px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 72px 16px 16px 16px;
        gap: 16px;
        background: var(--gray-main);
        border-radius: 20px;
        font-family: "Poppins", sans-serif;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: var(--dark);
        transition: color 0.3s ease-in-out, border 0.3s ease-in-out;
        user-select: none;
        cursor: pointer;

        &:hover {
            color: #5A7F67;
        }

        @media (max-width: 1365px) {
            width: 242px;
        }

        @media (max-width: 767px) {
            padding: 72px 16px 14px 16px;
            width: 170px;
        }

        &::after {
           content: "";
           position: absolute;
           top: 16px;
           left: 16px;
           width: 40px;
           height: 40px;
           background-position: center;
           background-repeat: no-repeat;
           background-size: contain;
        }

        &:nth-child(1)::after {
            background-image: url("../../../assets/images/registration/businessSelect/receive-payments.webp");
        }
        &:nth-child(2)::after {
            background-image: url("../../../assets/images/registration/businessSelect/personal.webp");
        }
        &:nth-child(3)::after {
            background-image: url("../../../assets/images/registration/businessSelect/employees.webp");
        }
        &:nth-child(4)::after {
            background-image: url("../../../assets/images/registration/businessSelect/currencies.webp");
        }
        &:nth-child(5)::after {
            background-image: url("../../../assets/images/registration/businessSelect/expenses.webp");
        }
        &:nth-child(6)::after {
            background-image: url("../../../assets/images/registration/businessSelect/invoice.webp");
        }
        &:nth-child(7)::after {
            background-image: url("../../../assets/images/registration/businessSelect/salary.webp");
        }

        &::before {
            content: "";
            position: absolute;
            top: 18px;
            right: 18px;
            width: 20px;
            height: 20px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            background-image: url("../../../assets/icons/registration/check.svg");
            transition: opacity 0.3s ease-in-out;
        }
    }

    input {
        top: 18px;
        right: 18px;
        position: absolute;
        cursor: pointer;
        visibility: hidden;
    }
}

.labelActive {
    border: 1px solid var(--green-second);

    &::before {
        opacity: 1;
    }
}
.labelPassive {
    border: 1px solid var(--gray-main);
    &::before {
        opacity: 0;
    }
}

