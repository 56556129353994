.otpGroup {
  display: flex;
  width: 100%;
  max-width: 350px;
  column-gap: 10px;
}

.otpInput {
  width: 100%;
  height: 50px;
  background-color: var(--gray-main);
  border: 1px solid var(--gray-first);
  border-radius: 10px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: var(--dark);
  text-align: center;

  &:focus,
  &:focus-visible {
    border: 1px solid var(--green-second);
    outline: none;
  }
}
