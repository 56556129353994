.notFoundContentContainer {
  height: 222px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: var(--dark);

  @media (max-width:767px) {
    height: calc(var(--vh, 1vh) * 80);
  }
}

.notFoundContentImage {
  width: 90px;
  height: 90px;
  margin: 0px 0px 16px 0px;
}
