.registerTop {
    h3{
        width: 482px;
    
        @media (max-width: 767px) {
            width: 80%;
        }
    }
}

.registerForm {
    width: 617px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    
    @media (max-width: 1365px) {
        width: 482px;
    }

    @media (max-width: 767px) {
        width: 357px;
    }

    label {
        position: relative;
        width: 100%;
        padding: 16px 16px 16px 72px;
        background-color: var(--gray-main);
        border-radius: 20px;
        transition: border 0.3s ease-in-out;
        user-select: none;
        cursor: pointer;

        &::after {
            content: "";
            position: absolute;
            top: 18px;
            left: 16px;
            width: 40px;
            height: 40px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
         }

        &:nth-child(1)::after {
            background-image: url("../../../assets/images/registration/businessRole/director.webp");
        }
        &:nth-child(2)::after {
            background-image: url("../../../assets/images/registration/businessRole/directors.webp");
        }
        &:nth-child(3)::after {
            background-image: url("../../../assets/images/registration/businessRole/no-director.webp");
        }

        &:hover p, &:hover span {
            color: #5A7F67;
        }

        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
        }

        p {
            font-family: "Poppins";
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            color: var(--dark);
            margin-bottom: 4px;
            transition: color 0.3s ease-in-out;
        }

        span {
            font-family: "Poppins";
            font-weight: 400;
            font-size: 13px;
            line-height: 20px;
            color: var(--dark-fifth);
            transition: color 0.3s ease-in-out;
        }
    }
}

.labelActive {
    border: 1px solid var(--green-second);
}
.labelPassive {
    border: 1px solid var(--gray-main);
}
