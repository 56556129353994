.create-pwd-block {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.create-pwd-container {
  position: relative;

  .create-pwd-input {
    position: relative;
    width: 350px;
    padding: 0;
    padding-left: 14px;
    padding-right: 14px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: var(--dark);
    border: 1px solid var(--gray-first);
    border-radius: 10px;
    background-color: var(--gray-main);
    box-shadow: none;

    &:hover {
      border-color: var(--gray-first);
    }

    &:focus-visible,
    &:focus-within,
    &:focus {
      border-color: var(--green-second) !important;

      // & + div {
      //   top: 4px;
      //   font-size: 10px;
      //   line-height: 14px;
      // }
    }

    input {
      padding-top: 21px;
      padding-bottom: 7px;
      line-height: 0;
      background-color: var(--gray-main);
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: var(--dark);

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus {
        -webkit-box-shadow: 0 0 0px 1000px var(--gray-main) inset !important;
      }
    }

    @media (max-width: 767px) {
      width: 300px;
    }
  }

  .create-pwd-label {
    position: absolute;
    top: 15px;
    left: 15px;
    display: flex;
    align-items: center;
    width: calc(100% - 30px);
    height: calc(100% - 30px);
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: var(--dark-fifth);
    pointer-events: none;
    transition: all 0.2s ease-in-out;
    z-index: 10;

    @media (max-width: 767px) {
      width: 300px;
    }

  }

  .create-pwd-label-active {
    top: 4px;
    font-size: 10px;
    line-height: 14px;
  }
}

.create-pwd-check {
  width: 350px;
  padding: 16px 16px 16px 16px;
  border: 1px solid var(--gray-first);
  border-radius: 10px;

  @media (max-width: 767px) {
    width: 300px;
  }

  .create-pwd-check-title {
    margin: 0px 0px 10px 0px;
    color: var(--dark);
    font: var(--headline-5);
  }

  .create-pwd-check-item {
    margin: 4px 0px 0px 0px;
    padding: 0px 0px 0px 25px;
    color: var(--dark);
    font: var(--body-3);
    background: no-repeat url('../../../assets/images/registration/password/check.webp');
    background-size: 18px 18px;
  }

  .create-pwd-check-item-active {
    margin: 4px 0px 0px 0px;
    padding: 0px 0px 0px 25px;
    color: var(--dark);
    font: var(--body-3);
    background: no-repeat url('../../../assets/images/registration/password/check-active.webp');
    background-size: 18px 18px;
  }
}





