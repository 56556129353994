.register {
  height: calc(var(--vh, 1vh) * 100);
  padding-top: 50px;
  padding-bottom: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  @media (max-width: 767px) {
    padding-top: 45px;
  }

  .registerTop {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;

    @media (max-width: 767px) {
      margin-bottom: 24px;
    }

    img {
      margin-bottom: 16px;
      width: 80px;
      height: 80px;
    }

    h3 {
      margin-bottom: 8px;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 34px;
      line-height: 40px;
      color: var(--dark);
      text-align: center;

      @media (max-width: 767px) {
        width: 80%;
        font-size: 24px;
        line-height: 30px;
      }
    }

    p {
      // width: 71%;
      width: 100%;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
      color: #808080;
      text-align: center;

      @media (max-width: 767px) {
        width: 90%;
      }
    }
  }

  .registerForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    overflow-y: auto;

    ::-webkit-scrollbar {
      width: 4px;               /* ширина всей полосы прокрутки */
    }

    ::-webkit-scrollbar-thumb {
      background-color: var(--gray-first);    /* цвет бегунка */
      border-radius: 20px;       /* округлось бегунка */
      border: 3px solid --gray-main;  /* отступ вокруг бегунка */
    }
  }
}

.backBtn {
  position: absolute;
  top: 8px;
  left: 12px;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  background-color: var(--green-first);
  background-image: url("../../assets/icons/registration/arrow-back.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 18px 18px;

  @media (max-width: 767px) {
    top: 10px;
    left: 8px;
    width: 24px;
    height: 24px;
    background-size: 14px 14px;
  }
}

.error {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: var(--red);
}

$offset: 190;
$duration: 1.4s;

.spinner {
  height: 30px;
  width: 30px;
  animation: rotator $duration linear infinite;

  @keyframes rotator {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(250deg); }
  }

  circle {
    stroke: #fff;
    stroke-dasharray: $offset;
    stroke-dashoffset: 0;
    transform-origin: center;
    animation: dash $duration ease-in-out infinite;
  }

  @keyframes dash {
    0% {
      stroke-dashoffset: $offset;
      transform: rotate(0deg);
     }
    50% {
      stroke-dashoffset: calc($offset / 4);
      transform:rotate(135deg);
    }
    100% {
      stroke-dashoffset: $offset;
      transform:rotate(450deg);
    }
  }
}
