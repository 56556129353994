.register-form-container {
  height: 100%;
  margin: 0px 0px 40px 0px;
}

.address-country-btn {
  width: 350px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 14px 15px 14px;
  margin: 0px 0px 16px 0px;
  background-color: var(--gray-main);
  background-image: url('../../../assets/icons/registration/arrow-down.svg');
  background-repeat: no-repeat;
  background-size: 22px 22px;
  background-position: 95% center;
  border: 1px solid var(--gray-first);
  border-radius: 10px;

  @media (max-width: 767px) {
    width: 300px;
  }
}

.address-country-btn-active {
  width: 350px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 14px 8px 14px;
  margin: 0px 0px 16px 0px;
  background-color: var(--gray-main);
  background-image: url('../../../assets/icons/registration/arrow-down.svg');
  background-repeat: no-repeat;
  background-size: 22px 22px;
  background-position: 95% center;
  border: 1px solid var(--gray-first);
  border-radius: 10px;
  text-align: left;

  @media (max-width: 767px) {
    width: 300px;
  }
}

.address-country-btn-text {
  color: var(--dark-fifth);
}

.address-country-btn-text-active {
  transform: translate(0px, -4px);
  text-align: left;
  color: var(--dark-fifth);
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
}

.postcode-country-btn {
  width: 350px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 14px 15px 14px;
  margin: 0px 0px 16px 0px;
  background-color: var(--gray-main);
  background-image: url('../../../assets/icons/registration/search-postcode.svg');
  background-repeat: no-repeat;
  background-size: 22px 22px;
  background-position: 95% center;
  border: 1px solid var(--gray-first);
  border-radius: 10px;

  @media (max-width: 767px) {
    width: 300px;
  }

  .postcode-country-btn-text {
    color: var(--dark-fifth);
  }
}

// inputs
.create-address-container {
  position: relative;

  .create-address-input {
    position: relative;
    width: 350px;
    padding: 0;
    padding-top: 21px;
    padding-bottom: 7px;
    padding-left: 14px;
    padding-right: 14px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: var(--dark);
    border: 1px solid var(--gray-first);
    border-radius: 10px;
    background-color: var(--gray-main);
    box-shadow: none;

    &:hover {
      border-color: var(--gray-first);
    }

    &:focus-visible,
    &:focus-within,
    &:focus {
      border-color: var(--green-second) !important;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      -webkit-box-shadow: 0 0 0px 1000px var(--gray-main) inset !important;
    }

    @media (max-width: 767px) {
      width: 300px;
    }
  }

  .create-address-label {
    position: absolute;
    top: 15px;
    left: 15px;
    display: flex;
    align-items: center;
    width: calc(100% - 30px);
    height: calc(100% - 30px);
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: var(--dark-fifth);
    pointer-events: none;
    transition: all 0.2s ease-in-out;
    // z-index: 100;
  }

  .create-address-label-active {
    top: 4px;
    font-size: 10px;
    line-height: 14px;
  }
}

.create-address-block {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.create-address-optional {
  margin: -14px 0px 0px 0px;
  color: var(--dark-fifth);
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
}

// address country modal
.address-country-modal .ant-modal {
  width: 400px !important;

  @media (max-width: 767px) {
    width: 100%;
  }

  .ant-modal-content {
    padding: 16px;
    border-radius: 20px;
    box-shadow: none;
  }

  .ant-modal-close {
    top: 19px;
    width: 34px;
    height: 34px;
    background-color: var(--gray-first);
    border-radius: 50%;

    &:hover {
      color: var(--white);
      background-color: var(--green-second);
      transition: background-color .3s ease-in-out,
                  color .3s ease-in-out;

      .ant-custom-close-icon {
        background-color: var(--white);
        transition: background-color .3s ease-in-out;
        &::before {
          background-color: var(--white);
          transition: background-color .3s ease-in-out;
        }
      }
    }
  }

  .ant-custom-close-icon {
    position: absolute;
    width: 16px;
    height: 1px;
    top: 17px;
    left: 9px;
    transform: rotate(45deg);
    background-color: var(--green-second);
    transition: background-color .3s ease-in-out;
    border-radius: 10px;

    &::before {
      content: '';
      position: absolute;
      width: 16px;
      height: 1px;
      left: 0px;
      top: 0px;
      transform: rotate(90deg);
      border-radius: 10px;
      background-color: var(--green-second);
      transition: background-color .3s ease-in-out;
    }
  }
}

// address country select
.address-country-select {
  width: 320px;
  height: 40px;

  @media (max-width: 767px) {
    width: 81vw;

    .ant-select-selector {
      width: 81vw !important;
    }
  }

  .ant-select-selector {
    height: 42px !important;
    padding: 10px 14px !important;
    border: 1px solid var(--green-second) !important;
    border-color: var(--green-second) !important;
    background-color: var(--gray-main) !important;
    border-radius: 10px;
    box-shadow: none !important;

    .ant-select-selection-search {
      inset-inline-start: 14px;
      inset-inline-end: 14px;

      .ant-select-selection-search-input {
        height: 100% !important;
        padding: 0px 0px 0px 24px !important;
        background: no-repeat url('../../../assets/images/registration/search.png') !important;
        background-size: 20px 20px !important;
        background-position: 0px 10px !important;
      }
    }

    .ant-select-selection-placeholder {
      width: 100%;
      padding: 1px 0px 0px 24px;
      font: var(--input);
      color: var(--dark-fifth);
    }

    .ant-select-selection-item {
      padding-inline-end: 0px;
      padding: 1px 16px 0px 24px;
      overflow-x: hidden;
      font: var(--input);
    }
  }

  .ant-select-clear {
    top: 46%;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    opacity: 1;
    // background: var(--dark-fifth) !important;
  }

  .anticon-custom-close-circle {
    position: absolute;
    width: 8px;
    height: 1px;
    top: 7px;
    left: 4px;
    transform: rotate(45deg);
    background-color: var(--gray-main);
    transition: background-color .3s ease-in-out;
    border-radius: 10px;

      &::before {
        content: '';
        position: absolute;
        width: 8px;
        height: 1px;
        left: 0px;
        top: 0px;
        transform: rotate(90deg);
        border-radius: 10px;
        background-color: var(--gray-main);
        transition: background-color .3s ease-in-out;
      }
  }

}

// address country dropdown
.address-country-select-dropdown {
  padding: 0;
  width: 370px;
  border-radius: 0px 0px 20px 20px;
  box-shadow: none;

  @media (max-width: 767px) {
    width: 100vw !important;
    height: calc(var(--vh, 1vh) * 85);
    left: 0 !important;
    border-radius: 0px 0px 0px 0px;
  }

  .rc-virtual-list {
    padding: 0;

    .rc-virtual-list-holder {

      .ant-select-item {
        margin: 0px 12px 0px 8px;
        padding: 12px 12px 12px 12px;
        font-weight: 400px !important;
        background-color: var(--white) !important;
        transition: background-color .3s ease-in-out;

        @media (max-width: 767px) {
          margin: 0px 0px 0px 16px;
          padding: 12px 12px 12px 12px;
        }

        &:hover {
          border-radius: 10px;
          background-color: var(--gray-main) !important;
          transition: background-color .3s ease-in-out;

          .address-country-option-name {
            color: var(--green-first);
            transition: color .3s ease-in-out;
          }
          .address-country-option-name-index {
            color: var(--green-first);
            transition: color .3s ease-in-out;
          }
        }
      }
    }

    .rc-virtual-list-scrollbar {
      display: block !important;
      bottom: 13px !important;
      background-color: var(--gray-first);
    }
    
    .rc-virtual-list-scrollbar-thumb {
      width: 4px !important;
      // height: 35% !important;
      left: 2px !important;
      border-radius: 48px !important;
      background-color: var(--gray-third) !important;
    }
  }
  
  .address-country-option-image {
    width: 34px;
    height: 34px;
    margin: 0px 16px 0px 0px;
  }

  .address-country-option-full-name {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 0px 0px 0px;

    @media (max-width: 767px) {
      padding: 0px 22px 0px 0px;
    }
  }

  .address-country-option-name {
    width: 80%;
    overflow-x: hidden;
    text-overflow: ellipsis;
    font : var(--input);
    color: var(--dark);
    transition: color .3s ease-in-out;
  }

  .address-country-option-name-index {
    color: var(--dark-fifth);
    transition: color .3s ease-in-out;
  }
}

// postcode modal
.postcode-country-modal .ant-modal {
  width: 400px !important;

  @media (max-width: 767px) {
    width: 100%;
  }

  .ant-modal-content {
    padding: 16px 16px 16px 16px;
    border-radius: 20px 20px 20px 20px;
    box-shadow: none;
  }

  .ant-modal-close {
    top: 19px;
    width: 34px;
    height: 34px;
    background-color: var(--gray-first);
    border-radius: 50%;

    &:hover {
      color: var(--white);
      background-color: var(--green-second);
      transition: background-color .3s ease-in-out,
                  color .3s ease-in-out;

      .ant-custom-close-icon {
        background-color: var(--white);
        transition: background-color .3s ease-in-out;
        &::before {
          background-color: var(--white);
          transition: background-color .3s ease-in-out;
        }
      }
    }
  }

  .ant-custom-close-icon {
    position: absolute;
    width: 16px;
    height: 1px;
    top: 17px;
    left: 9px;
    transform: rotate(45deg);
    background-color: var(--green-second);
    transition: background-color .3s ease-in-out;
    border-radius: 10px;

    &::before {
      content: '';
      position: absolute;
      width: 16px;
      height: 1px;
      left: 0px;
      top: 0px;
      transform: rotate(90deg);
      border-radius: 10px;
      background-color: var(--green-second);
      transition: background-color .3s ease-in-out;
    }
  }
}

// postcode select
.postcode-country-select {
  width: 320px;
  height: 40px;

  @media (max-width: 767px) {
    width: 81vw;

    .ant-select-selector {
      width: 81vw !important;
    }
  }

  .ant-select-selector {
    height: 42px !important;
    padding: 10px 14px !important;
    border: 1px solid var(--green-second) !important;
    border-color: var(--green-second) !important;
    background-color: var(--gray-main) !important;
    border-radius: 10px;
    box-shadow: none !important;

    .ant-select-selection-search {
      inset-inline-start: 14px;
      inset-inline-end: 14px;

      .ant-select-selection-search-input {
        height: 100% !important;
        padding: 0px 0px 0px 24px !important;
        background: no-repeat url('../../../assets/images/registration/search.png') !important;
        background-size: 20px 20px !important;
        background-position: 0px 10px !important;
      }
    }

    .ant-select-selection-placeholder {
      width: 100%;
      padding: 1px 0px 0px 24px;
      font: var(--input);
      color: var(--dark-fifth);
    }

    .ant-select-selection-item {
      padding-inline-end: 0px;
      padding: 1px 16px 0px 24px;
      overflow-x: hidden;
      font: var(--input);
    }
  }

  .ant-select-clear {
    top: 46%;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    opacity: 1;
    background: none;
    // background: var(--dark-fifth) !important;
  }

  .anticon-custom-close-circle {
    position: absolute;
    width: 8px;
    height: 1px;
    top: 7px;
    left: 4px;
    transform: rotate(45deg);
    background-color: var(--gray-main);
    transition: background-color .3s ease-in-out;
    border-radius: 10px;

      &::before {
        content: '';
        position: absolute;
        width: 8px;
        height: 1px;
        left: 0px;
        top: 0px;
        transform: rotate(90deg);
        border-radius: 10px;
        background-color: var(--gray-main);
        transition: background-color .3s ease-in-out;
      }
  }

}

// postcode dropdown
.postcode-country-select-dropdown {
  padding: 0;
  width: 370px;
  border-radius: 0px 0px 20px 20px;
  box-shadow: none;

  @media (max-width: 767px) {
    width: 100vw !important;
    height: calc(var(--vh, 1vh) * 85);
    left: 0 !important;
    border-radius: 0px 0px 0px 0px;
  }

  .rc-virtual-list {
    padding: 0;

    .rc-virtual-list-holder {

      .ant-select-item {
        margin: 0px 8px 0px 8px;
        padding: 12px 12px 12px 12px;
        font-weight: 400px !important;
        background-color: var(--white) !important;
        transition: background-color .3s ease-in-out;

        @media (max-width: 767px) {
          margin: 0px 0px 0px 16px;
          padding: 12px 12px 12px 12px;
        }

        &:hover {
          border-radius: 10px;
          background-color: var(--gray-main) !important;
          transition: background-color .3s ease-in-out;

          .postcode-country-option-name {
            color: var(--green-first);
            transition: color .3s ease-in-out;
          }
          .postcode-country-option-name-index {
            color: var(--green-first);
            transition: color .3s ease-in-out;
          }
        }
      }
    }

    .rc-virtual-list-scrollbar {
      display: block !important;
      bottom: 13px !important;
      background-color: var(--gray-first);
    }
    
    .rc-virtual-list-scrollbar-thumb {
      width: 4px !important;
      // height: 35% !important;
      left: 2px !important;
      border-radius: 48px !important;
      background-color: var(--gray-third) !important;
    }
  }
  
  .postcode-country-option-image {
    width: 34px;
    height: 34px;
    margin: 0px 16px 0px 0px;
  }

  .postcode-country-option-full-name {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // align-items: center;
    padding: 0px 5px 0px 0px;
  }

  .postcode-country-option-name {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    font : var(--input);
    color: var(--dark);
    transition: color .3s ease-in-out;
  }

  // .postcode-country-option-name-index {
  //   color: var(--dark-fifth);
  //   transition: color .3s ease-in-out;
  // }
}

