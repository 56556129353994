.registration-modal {
  position: fixed;
  height: calc(var(--vh, 1vh) * 100);
  width: 100vw;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  z-index: 10;

  @media (max-width: 767px) {
    transition: opacity .3s ease-in-out .2s;
  }

  .registration-modal-content {
    display: flex;
    justify-content: space-between;
    padding: 20px 16px 20px 16px;
    border-radius: 20px 20px 0px 0px;
    background-color: #fff;

    @media (max-width: 767px) {
      width: 100%;
      height: 100%;
      transform: translateY(calc(var(--vh, 1vh) * 100));
      transition: transform .3s ease-in-out;

      .ant-modal-close {
        position: relative;
        width: 34px;
        height: 34px;
        margin: 2px 0px 0px 0px;
        background-color: var(--gray-first);
        border-radius: 50%;
    
          .ant-custom-close-icon {
            position: absolute;
            width: 16px;
            height: 1px;
            top: 17px;
            left: 9px;
            transform: rotate(45deg);
            background-color: var(--green-second);
            transition: background-color .3s ease-in-out;
            border-radius: 10px;
        
            &::before {
              content: '';
              position: absolute;
              width: 16px;
              height: 1px;
              left: 0px;
              top: 0px;
              transform: rotate(90deg);
              border-radius: 10px;
              background-color: var(--green-second);
              transition: background-color .3s ease-in-out;
            }
          }
      }
    }
  }

  .registration-modal-content-error {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 16px 20px 16px;
    border-radius: 20px 20px 0px 0px;
    background-color: #fff;

    @media (max-width: 767px) {
      width: 100%;
      height: 50%;
      transform: translateY(calc(var(--vh, 1vh) * 100));
      transition: transform .3s ease-in-out;

      .ant-modal-close {
        position: relative;
        width: 34px;
        height: 34px;
        margin: 2px 0px 0px 0px;
        background-color: var(--gray-first);
        border-radius: 50%;
    
          .ant-custom-close-icon {
            position: absolute;
            width: 16px;
            height: 1px;
            top: 17px;
            left: 9px;
            transform: rotate(45deg);
            background-color: var(--green-second);
            transition: background-color .3s ease-in-out;
            border-radius: 10px;
        
            &::before {
              content: '';
              position: absolute;
              width: 16px;
              height: 1px;
              left: 0px;
              top: 0px;
              transform: rotate(90deg);
              border-radius: 10px;
              background-color: var(--green-second);
              transition: background-color .3s ease-in-out;
            }
          }
      }
    }
  }

  .registration-modal-content-change-country {
    display: flex;
    justify-content: center;
    padding: 20px 16px 20px 16px;
    border-radius: 20px 20px 0px 0px;
    background-color: #fff;

    @media (max-width: 767px) {
      width: 100%;
      height: 60%;
      transform: translateY(calc(var(--vh, 1vh) * 100));
      transition: transform .3s ease-in-out;

      .ant-modal-close {
        position: relative;
        width: 34px;
        height: 34px;
        margin: 2px 0px 0px 0px;
        background-color: var(--gray-first);
        border-radius: 50%;
    
          .ant-custom-close-icon {
            position: absolute;
            width: 16px;
            height: 1px;
            top: 17px;
            left: 9px;
            transform: rotate(45deg);
            background-color: var(--green-second);
            transition: background-color .3s ease-in-out;
            border-radius: 10px;
        
            &::before {
              content: '';
              position: absolute;
              width: 16px;
              height: 1px;
              left: 0px;
              top: 0px;
              transform: rotate(90deg);
              border-radius: 10px;
              background-color: var(--green-second);
              transition: background-color .3s ease-in-out;
            }
          }
      }
    }
  }
}

.active {
  opacity: 1;
  pointer-events: all;

  @media (max-width: 767px) {
    transition: opacity 0s ease-in-out;

    .registration-modal-content {
      transform: translateY(calc(var(--vh, 1vh) * 5));
      transition: transform .3s ease-in-out;
    }
    .registration-modal-content-error {
      transform: translateY(calc(var(--vh, 1vh) * 28));
      transition: transform .3s ease-in-out;
    }

    .registration-modal-content-change-country {
      transform: translateY(calc(var(--vh, 1vh) * 25));
      transition: transform .3s ease-in-out;
    }
  }
}
